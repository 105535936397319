html {
	font-size: 13px;
	scroll-behavior: smooth;
}
body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
		'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow-x: hidden;
}
.main-container {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}
main {
	/* min-height: calc(100vh - 410px); */
	flex-grow: 1;
	flex-shrink: 0;
	flex-basis: auto;
}
footer {
	background-color: #d4d4d4;
	bottom: 0;
	min-height: 100px;
	width: 100%;
	margin-top: 50px;
	padding-top: 20px;
	font-size: 14px;

	flex-grow: 0;
	flex-shrink: 0;
	flex-basis: auto;
}
.footer-copirigt {
	/* position: relative; */
	padding: 20px;
	background-color: #016193;
	color: #fff;
	/* width: 100%; */
	font-size: 12px;
	font-weight: 300;
	bottom: 0;
}
.footer-container {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
	grid-gap: 1rem;
	padding: 20px;
	min-height: 160px;
}
.logo-footer {
	width: 80px;
}
.footer-container a {
	text-decoration: none;
	color: rgb(110, 110, 110);
}
.footer-menu-item {
	margin-bottom: 10px;
	color: rgb(110, 110, 110);
}

h1 {
	font-size: 2rem;
}
h2 {
	font-size: 1.8rem;
}
.code-block--body--var {
	font-size: 1.2rem;
}
.code-block--body--var sup {
	font-size: 0.9rem;
}
.code-block--body--type {
	font-size: 1.1rem;
	font-style: italic;
}
.code-block--body--description {
	font-size: 1rem;
	color: #000000c0;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.empty__text {
	color: #016193;
	font-weight: bold;
}

.vertical-nav-item {
	color: #016193;
	font-weight: bold;
	text-decoration: none;
	background-color: transparent;
	outline: 0px;
	border: 0px;
	font-size: 1.2rem;
	justify-self: start;
	cursor: pointer;
	text-align: start;
	/* margin-bottom: 10px; */
}
.vertical-nav-item-without-accordion {
	margin-left: 15px;
	margin-bottom: 10px;
}
.vertical-nav-item-2 {
	/* color: black; */
	font-weight: normal;
	margin-left: 15px;
	display: flex;
	flex-direction: row;
	margin-bottom: 10px;
}
.vertical-nav-item-2:hover {
	background-color: #0161930d;
}

.api-method::before {
	border-radius: 10px;
	margin-right: 10px;
	font-size: 1rem;
	font-weight: bold;
	width: 4.5rem;
	height: 1.9rem;
	display: inline-flex;
	align-items: center;
	justify-content: center;
}

.api-method-post::before {
	content: 'POST';
	color: #23c263;
	background-color: #9fffc6;
}

.api-body h3 {
	margin: 0 0 20px 0;
}
.api-method-get::before {
	content: 'GET';
	color: rgb(0, 143, 225);
	background-color: #99d9ff;
}
.api-method-delete::before {
	content: 'DELETE';
	color: #e1007c;
	background-color: #ffa4d6;
}
.api-method-put::before {
	content: 'PUT';
	color: #e19100;
	background-color: #ffcc6e;
}
.api-method-patch::before {
	content: 'PATCH';
	color: #e19100;
	background-color: #ffcc6e;
}

.api-alert-get--container {
	background-color: #038fe1;
}
.api-alert-post--container {
	background-color: #23c263;
}
.api-alert-delete--container {
	background-color: #e1007c;
}
.api-alert-put--container {
	background-color: #e19100;
}
.api-alert-patch--container {
	background-color: #e19100;
}

.vertical-nav-item-2--name {
	align-items: start;
	text-align: start;
}
.MuiAccordion-root::before {
	background-color: #fff0 !important;
}
.MuiAccordion-root {
	background-color: #fff0 !important;
}

.code-block {
	background-color: #f5f5f5;
	padding: 10px;
	border-radius: 5px;
	margin-bottom: 10px;
}
.code-block--title {
	font-size: 14px;
	font-weight: bold;
	margin-bottom: 10px;
}
.code-block--code {
	font-family: monospace;
	white-space: pre-wrap;
	word-wrap: break-word;
	color: #000000a0;
	background: #f4d30033;
	border-radius: 10px;
	padding: 0px 5px;
	display: inline-block;
}

.name-in-header {
	z-index: 2000;
	position: fixed;
	top: 0;
}
.api-name.name-in-header {
	/* left: 150px; */
	/* margin-top: 5px; */
}

.api-url.name-in-header div {
	position: static;
	/* right: 0; */
	/* margin-top: 5px; */
	/* width: calc(100% - 150px - 500px); */
}

@media (max-width: 1450px) {
	.api-header--title {
		display: none;
	}
}

@media (max-width: 890px) {
	.api-header--url {
		display: none;
	}
}
